<template>
<div class="login-wrapper">
    <!-- Card -->
    <div class="card mx-xl-5">

        <!-- Card body -->
        <div class="card-body">
            <form @keyup.enter="login">
                <p class="h2 text-center py-4">{{ $t('Login') }}</p>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" ><b-icon-envelope></b-icon-envelope></span>
                    </div>
                    <input
                        @input="errorMessage = null"
                        v-model="email"
                        type="text"
                        :placeholder="$t('Login_Email')"
                        class="form-control">
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" ><b-icon-lock></b-icon-lock></span>
                    </div>
                    <input 
                        @input="errorMessage = null"
                        v-model="password"
                        type="password"
                        :placeholder="$t('Login_Password')"
                        class="form-control">
                </div>

                <b-alert :show="!!errorMessage" variant="warning" fade>
                    {{errorMessage}}
                </b-alert>
            </form>
        
            <div class="text-center py-2 mt-4">
                <button class="btn btn-primary" @click="login">
                    {{ $t('Send') }}
                </button>
            </div>
        </div>
        <!-- Card body -->

    </div>
    <!-- Card -->
</div>
</template>

<script>
import { postLogin } from "@/apis/apiAuth";
import { BAlert } from 'bootstrap-vue';

export default {
    name: 'Login',

    metaInfo() {
        return {
            title: this.$t('Login'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('Head_description')
                } ,{
                    name: 'keywords',
                    content: this.$t('Head_keywords')
                }, {
                    name: 'author',
                    content: this.$t('Head_author')
                }, {
                    name: 'copyright',
                    content: this.$t('Head_copyright')
                }
            ]
        }
    },

    components: {
        BAlert,
    },

    data() {
        return {
            email: '',
            password: '',
            errorMessage: null,
        }
    },

    methods: {
        async login() {
            // need to replace use validate and remove next
            if (!this.email || !this.password) {
                this.errorMessage = '請確認填入的欄位值';
                return;
            }
            let result = await postLogin({email: this.email, password: this.password});
            
            if (result.status !== 200) {
                this.errorMessage = result.message;
                return;
            } else {
                this.$router.push({
                    path: '/admin'
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login-wrapper{
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
}

.card {
    width: 400px;
}

.h2{
    font-weight: 300;
}
</style>